import * as React from 'react';
import Section from "./Section";
import Content from "./Content";
import Subtitle from "./Subtitle";
import PageTitle from "./PageTitle";
import PageHeader from "./PageHeader";
import ContentColumns from "./ContentColumns";
import DoubleImage from "./DoubleImage";
import {getImage} from "../contentful/data-provider";
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {renderRichText} from "gatsby-source-contentful/rich-text";

const EnviromentPageSection = ({enviromentPage, lowerSection, isEnvironmentContentColumns}) => {
    const renderPageTitle = () => {
        if (enviromentPage.subtitle) {
            return (
                <>
                    <div>{enviromentPage.subtitle}</div>
                    <h1>{enviromentPage.title}</h1>
                </>
            )
        }

        return <h1>{enviromentPage.title}</h1>;
    }

    const options = {
        renderNode: {
            [BLOCKS.HEADING_2]: (node, children) => {
                return (
                    <Subtitle isBasicPageSection>
                        <h2>{children}</h2>
                    </Subtitle>
                )
            },
            [INLINES.HYPERLINK]: (node, children) => {
                console.log(node);
                return (
                    <a href={node.data.uri} target={'_blank'}>{children}</a>
                )
            },
        },
    };

    return (
        <>
            <PageHeader>
                <PageTitle>
                    {renderPageTitle()}
                </PageTitle>
            </PageHeader>
            <Section hasMaxWidth isBasicPageSection isBlock>
                <ContentColumns isFullWidth isReverseColumnOnMobile isEnvironmentContentColumns={isEnvironmentContentColumns}>
                    <div>
                        <Content>
                            <div>
                                {renderRichText(enviromentPage.body, options)}
                            </div>
                        </Content>
                    </div>
                    <div>
                        { enviromentPage.images.length >= 2 &&
                            <DoubleImage
                                firstImageSrc={getImage(enviromentPage.images[0]).url}
                                firstImageDescription={getImage(enviromentPage.images[0]).description}
                                secondImageSrc={getImage(enviromentPage.images[1]).url}
                                secondImageDescription={getImage(enviromentPage.images[1]).description}
                            />
                        }
                    </div>
                </ContentColumns>
                <ContentColumns isFullWidth isReverseColumnOnMobile isEnvironmentContentColumns={isEnvironmentContentColumns}>
                    <div>
                        <Content>
                            <div>
                                {renderRichText(lowerSection.body, options)}
                            </div>
                        </Content>
                    </div>
                    <div>
                        { lowerSection.images.length >= 2 &&
                            <DoubleImage
                                firstImageSrc={getImage(lowerSection.images[0]).url}
                                firstImageDescription={getImage(lowerSection.images[0]).description}
                                secondImageSrc={getImage(lowerSection.images[1]).url}
                                secondImageDescription={getImage(lowerSection.images[1]).description}
                            />
                        }
                    </div>
                </ContentColumns>
            </Section>
        </>
    );
};

export default EnviromentPageSection;
